import React from "react"
import styled from "styled-components"

const Container = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  display: -ms-flexbox;
  flex: auto;
  -ms-flex: auto;
  -ms-flex-pack: center;
  flex-direction: column;
  -ms-flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 10px;
  position: relative;
  h2 {
    margin-top: 1rem;
  }
`

const Form = styled.form`
  position: relative;
  width: 100%;
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  div.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    flex-wrap: nowrap;
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    width: 100%;
    padding: 11px 15px 11px 0;
    color: #000;
    background-color: transparent;
    -webkit-animation: 1ms void-animation-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }
`

const Fieldset = styled.fieldset`
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #f7f7f7;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`
const Button = styled.button`
  background-color: rgb(0, 116, 217);
  border-radius: 4px;
  border-style: none;
  box-shadow: 0 6px 9px rgba(93, 92, 50, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffb9f6;
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: 600;
  height: 40px;
  margin: 40px 15px 0;
  outline: none;
  overflow: hidden;
  position: relative;
  width: calc(100% - 30px);
`
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.domRef = React.createRef()
    this.state = { feedbackMsg: null, status: undefined }
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    console.log(
      encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      })
    )
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() =>
        this.setState({
          status: "success",
          feedbackMsg:
            "Grazie per averci scritto! Ti risponderemo appena possibile!",
        })
      )
      .catch(error =>
        this.setState({
          status: "error",
          feedbackMsg: "Qualcosa è andato storto... potresti riprovare?",
        })
      )
  }

  render() {
    return (
      <Container>
        <h2 id="contact">How can I help?</h2>
        <Form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </p>
          <Fieldset>
            <div className="row">
              <input
                aria-label="Your name"
                type="text"
                name="name"
                placeholder="Your name"
                required
                onChange={this.handleChange}
              />
            </div>
          </Fieldset>
          <Fieldset>
            <div className="row">
              <input
                aria-label="Your email"
                type="email"
                name="email"
                placeholder="Your email"
                required
                onChange={this.handleChange}
              />
            </div>
          </Fieldset>
          <Fieldset>
            <div className="row">
              <textarea
                aria-label="Your message"
                name="message"
                col="20"
                rows="4"
                placeholder="Your message"
                required
                onChange={this.handleChange}
              />
            </div>
          </Fieldset>
          {this.state.feedbackMsg && <p>{this.state.feedbackMsg}</p>}

          <Button type="submit">Invia</Button>
        </Form>
      </Container>
    )
  }
}

export default Contact
