import React from "react"
import styled from "styled-components"

const Container = styled.div`
  margin: 0.5rem;
  text-align: right
`

const LinkContainer = ({block, key}) => (
    <Container key={key}><a title={block.title} href={block.href}>{block.title}</a></Container>
)

export default LinkContainer