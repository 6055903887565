import React from "react"
import styled from "styled-components"
const Section = styled.section`
  margin: 0.5rem;
  blockquote {
    border-left: 2px solid #e4e4e4;
    font-style: italic;
    padding-left: 20px;
  }
`

const SectionComponent = ({title, tag, paragraphs}, i) => {
    const CustomTag = `${tag}`
    return (
        <Section key={i}>
            <CustomTag>{title}</CustomTag>
            {paragraphs.map((p, i) => (
                <p key={i} dangerouslySetInnerHTML={{ __html: p}} />
            ))}
        </Section>
    )
}
export default SectionComponent