import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import LinkContainer from "../molecules/linkContainer"
import Section from "../molecules/section"

const Bio = () => {
    const data = useStaticQuery(graphql`
      query MyQuery {
        allSectionsJson {
          edges {
            node {
              title
              tag
              paragraphs
              lang
              active
              href
            }
          }
        }
      }
    `)
  const sections = data.allSectionsJson.edges
  return (
  <>
  {sections
    .filter(({node: s}) => s.active && s.lang === "en")
    .map(({ node: s }, i) => {
      switch (s.tag) {
        case "a":
          return <LinkContainer block={s} key={i} />
      
        default:
          return <Section key={i} title={s.title} tag={s.tag} paragraphs={s.paragraphs} />
      }
    }
    )}
  </>
)}
export default Bio