import React from "react"

import Layout from "../layouts/layout"
import LinkContainer from "../components/molecules/linkContainer"
import Bio from "../components/organisms/Bio"
import Contact from "../components/organisms/Contact"

import Seo from "../components/organisms/seo"

const blogBlock = {
  title: "Blog",
  href: "https://blog.giovannidemizio.eu",

}
const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <LinkContainer key="blog-link" block={blogBlock} />
    <Bio />
    <Contact />
  </Layout>
)

export default IndexPage
